
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import { randId } from '@/utils/components';
import { isSelectOptions, SelectOption } from '@/components/form/form.types';
import HelpTooltip from '@/components/HelpTooltip.vue';

const required = true;
@Component({
  components: {
    HelpTooltip,
  },
})
export default class GCheckboxGroup extends Vue {
  @Prop({ type: Array, required }) readonly value!: DeepReadonly<SelectOption['value'][]>;
  @Prop({ type: Array, required }) readonly options!: SelectOption[] | string[];
  @Prop({ type: String, default: 'cb' }) readonly name!: string;

  isChecked(option: { value: string }) {
    return this.value.includes(option.value);
  }

  get randName() {
    return `${this.name}_${randId()}`;
  }

  /**
   * workaround for not being able to add options after render
   */
  get key() {
    return JSON.stringify(this.options);
  }

  get formattedOptions(): SelectOption[] {
    if (!isSelectOptions(this.options)) {
      return this.options.map(option => {
        return {
          key: option,
          value: option,
          label: option,
          disabled: false,
        };
      });
    }

    return this.options.map(option => {
      const out = { ...option };
      if (!out.key) {
        out.key = out.value;
      }
      if (!out.label) {
        out.label = out.value;
      }
      return out;
    });
  }

  get eventHandlers() {
    return {
      change: this.input,
      blur: (e: Event) => this.$emit('blur', e),
    };
  }

  // currently gsk-radio-group emits 3 "change" events...
  input(e: CustomEvent): void {
    if (e.detail === undefined) {
      // skip the native event that does not have e.detail set
      return;
    }
    let value: string | string[] = e.detail.value;
    if (typeof value === 'string') {
      this.$emit('input', value.split(','));
      this.$emit('change', value.split(','));
    } else {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
}
