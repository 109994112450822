
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { randId } from '@/utils/components';
// Markdown Editor
import '@toast-ui/editor/dist/toastui-editor.css';
import 'codemirror/lib/codemirror.css';

@Component({
  components: {
    Editor: () => import('@toast-ui/vue-editor/src/Editor.vue'),
  },
})
export default class MarkdownEditor extends Vue {
  @Prop({ required: true, type: String }) readonly value!: string;
  @Prop({ type: String, default: '175px' }) readonly height!: string;
  @Prop({ type: String, default: 'wysiwyg' }) readonly mode!: string;

  @Prop({ type: String, default: '' }) readonly label!: string;
  @Prop({ type: String, default: '' }) readonly helperText!: string;
  @Prop({ type: Boolean }) readonly persistentHelperText!: string;
  @Prop({ type: String, default: 'Invalid' }) readonly validationMessage!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly error!: boolean;

  public defaultOptions = { hideModeSwitch: true, minHeight: this.height };
  dirty = false;

  @Ref('editor')
  editor!: import('@toast-ui/vue-editor/index').Editor;

  onChange() {
    this.val = this.editor.invoke('getMarkdown');
  }

  get internalError() {
    return this.error || (this.required && this.dirty && !this.val);
  }

  get id() {
    return `me-${randId()}`;
  }

  get val() {
    return this.value;
  }

  set val(v: string) {
    this.$emit('input', v);
  }
}
